import request from './request'

const auth = {
    init(environment) {
        return request.get(`/init/${environment}`)
    },

    guestLogin(payload) {
        return request.post(`/player/login/guest`, payload)
    },

    googleLogin(payload) {
        return request.get(`/player/login/google`, {headers: {'X-Id-Token': payload.token, 'X-Access-Token': payload.access}})
    },

    facebookLogin(payload) {
        return request.get(`/player/login/facebook`, {headers: {'X-Id-Token': payload.token, 'X-Id-User': payload.userID}})
    },

    login(payload){
        return request.post(`/player/login`, payload)
    },

    register(payload){
        return request.post(`/player`, payload)
    },

    passwordChange(payload){
        return request.post(`/player/${payload.id}/changePassword`, payload)
    },

    passwordResetConfirmation(payload){
        return request.post(`/player/password/confirmation`, payload.data)
    },

    passwordReset(payload){
        return request.post(`/player/password/reset`, payload);
    },
    
    logout(){
        return request.get(`/player/logout`)
    },

    disconnectPlatform(platform) {
        return request.patch(`/player/disconnect/${platform}`);
    }
}

export default auth