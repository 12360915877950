<template>
  <div id="app">
    <div class="layout-main vld-parent">
      <the-header></the-header>
      <div class="main mx-auto flex flex-col items-center">
        <div class="flex container px-8 mt-8 flex-col items-center justify-center">
          <h1 class="mt-2 mb-2">REHBER</h1>
          <h2 class="mb-6">Oyunda Ödül Nasıl Kazanırım ?</h2>
          <p class="mb-4 text-center">Raviosoft Adam Asmaca oyununda çok çeşitli ödüller kazanabilirsin. En belirgin ödül kazanma yöntemi haftalık ligde sıralamaya girebilmektir. Oyunda ilk 50 sıradaki oyuncular çeşitli miktarlarda koin ve güç ödülleri kazanır. Ayrıca oyuncular ödüllü reklamları izleyerek oyun bileti, güç ve koin kazanabilir. Bir diğer ücretsiz ödül kazanma yöntemi ise oyuncuların oyun sonunda kendi skorlarını sosyal medyadan paylaşarak veya sosyal medya hesaplarını oyuna bağlayarak kazanabilirler.</p>
          <h2 class="mb-4 mt-6">Lig Ödülleri</h2>
          <table class="table w-4/5 mt-2 mb-6">
            <thead>
              <tr>
                <th>
                  Lig Sıralaması
                </th>
                <th>
                  Ödüller
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>1. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>1 saat sınırsız bilet</li>
                        <li>750 koin</li>
                        <li>10 x harf açma gücü</li>
                        <li>20 x ipucu gücü</li>
                        <li>20 x pas gücü</li>
                        <li>20 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>2. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>1 saat sınırsız bilet</li>
                        <li>700 koin</li>
                        <li>10 x harf açma gücü</li>
                        <li>15 x ipucu gücü</li>
                        <li>15 x pas gücü</li>
                        <li>15 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>3. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>1 saat sınırsız bilet</li>
                        <li>650 koin</li>
                        <li>10 x harf açma gücü</li>
                        <li>10 x ipucu gücü</li>
                        <li>15 x pas gücü</li>
                        <li>10 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>4. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>600 koin</li>
                        <li>5 x harf açma gücü</li>
                        <li>10 x ipucu gücü</li>
                        <li>15 x pas gücü</li>
                        <li>10 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>5. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>600 koin</li>
                        <li>5 x harf açma gücü</li>
                        <li>10 x ipucu gücü</li>
                        <li>15 x pas gücü</li>
                        <li>10 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>6. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>500 koin</li>
                        <li>5 x harf açma gücü</li>
                        <li>5 x ipucu gücü</li>
                        <li>10 x pas gücü</li>
                        <li>10 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>7. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>500 koin</li>
                        <li>5 x harf açma gücü</li>
                        <li>5 x ipucu gücü</li>
                        <li>10 x pas gücü</li>
                        <li>10 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>8. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>500 koin</li>
                        <li>5 x harf açma gücü</li>
                        <li>5 x ipucu gücü</li>
                        <li>10 x pas gücü</li>
                        <li>10 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>9. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>500 koin</li>
                        <li>5 x harf açma gücü</li>
                        <li>5 x ipucu gücü</li>
                        <li>10 x pas gücü</li>
                        <li>10 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>10. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>500 koin</li>
                        <li>5 x harf açma gücü</li>
                        <li>5 x ipucu gücü</li>
                        <li>10 x pas gücü</li>
                        <li>10 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>11. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>400 koin</li>
                        <li>3 x harf açma gücü</li>
                        <li>5 x ipucu gücü</li>
                        <li>10 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>12. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>400 koin</li>
                        <li>3 x harf açma gücü</li>
                        <li>5 x ipucu gücü</li>
                        <li>10 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>13. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>400 koin</li>
                        <li>3 x harf açma gücü</li>
                        <li>5 x ipucu gücü</li>
                        <li>10 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>14. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>400 koin</li>
                        <li>3 x harf açma gücü</li>
                        <li>5 x ipucu gücü</li>
                        <li>10 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>15. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>400 koin</li>
                        <li>3 x harf açma gücü</li>
                        <li>5 x ipucu gücü</li>
                        <li>10 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>16. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>400 koin</li>
                        <li>3 x harf açma gücü</li>
                        <li>5 x ipucu gücü</li>
                        <li>10 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>17. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>400 koin</li>
                        <li>3 x harf açma gücü</li>
                        <li>5 x ipucu gücü</li>
                        <li>10 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>18. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>400 koin</li>
                        <li>3 x harf açma gücü</li>
                        <li>5 x ipucu gücü</li>
                        <li>10 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>19. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>400 koin</li>
                        <li>3 x harf açma gücü</li>
                        <li>5 x ipucu gücü</li>
                        <li>10 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>20. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>400 koin</li>
                        <li>3 x harf açma gücü</li>
                        <li>5 x ipucu gücü</li>
                        <li>10 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>21. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>300 koin</li>
                        <li>3 x harf açma gücü</li>
                        <li>3 x ipucu gücü</li>
                        <li>5 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>22. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>300 koin</li>
                        <li>3 x harf açma gücü</li>
                        <li>3 x ipucu gücü</li>
                        <li>5 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>23. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>300 koin</li>
                        <li>3 x harf açma gücü</li>
                        <li>3 x ipucu gücü</li>
                        <li>5 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>24. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>300 koin</li>
                        <li>3 x harf açma gücü</li>
                        <li>3 x ipucu gücü</li>
                        <li>5 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>25. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>300 koin</li>
                        <li>3 x harf açma gücü</li>
                        <li>3 x ipucu gücü</li>
                        <li>5 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>26. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>300 koin</li>
                        <li>3 x harf açma gücü</li>
                        <li>3 x ipucu gücü</li>
                        <li>5 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>27. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>300 koin</li>
                        <li>3 x harf açma gücü</li>
                        <li>3 x ipucu gücü</li>
                        <li>5 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>28. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>300 koin</li>
                        <li>3 x harf açma gücü</li>
                        <li>3 x ipucu gücü</li>
                        <li>5 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>29. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>300 koin</li>
                        <li>3 x harf açma gücü</li>
                        <li>3 x ipucu gücü</li>
                        <li>5 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>30. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>300 koin</li>
                        <li>3 x harf açma gücü</li>
                        <li>3 x ipucu gücü</li>
                        <li>5 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>31. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>200 koin</li>
                        <li>1 x harf açma gücü</li>
                        <li>1 x ipucu gücü</li>
                        <li>5 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>32. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>200 koin</li>
                        <li>1 x harf açma gücü</li>
                        <li>1 x ipucu gücü</li>
                        <li>5 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>33. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>200 koin</li>
                        <li>1 x harf açma gücü</li>
                        <li>1 x ipucu gücü</li>
                        <li>5 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>34. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>200 koin</li>
                        <li>1 x harf açma gücü</li>
                        <li>1 x ipucu gücü</li>
                        <li>5 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>35. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>200 koin</li>
                        <li>1 x harf açma gücü</li>
                        <li>1 x ipucu gücü</li>
                        <li>5 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>36. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>200 koin</li>
                        <li>1 x harf açma gücü</li>
                        <li>1 x ipucu gücü</li>
                        <li>5 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>37. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>200 koin</li>
                        <li>1 x harf açma gücü</li>
                        <li>1 x ipucu gücü</li>
                        <li>5 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>38. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>200 koin</li>
                        <li>1 x harf açma gücü</li>
                        <li>1 x ipucu gücü</li>
                        <li>5 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>39. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>200 koin</li>
                        <li>1 x harf açma gücü</li>
                        <li>1 x ipucu gücü</li>
                        <li>5 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>40. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>200 koin</li>
                        <li>1 x harf açma gücü</li>
                        <li>1 x ipucu gücü</li>
                        <li>5 x pas gücü</li>
                        <li>5 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>41. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>100 koin</li>
                        <li>1 x harf açma gücü</li>
                        <li>1 x ipucu gücü</li>
                        <li>3 x pas gücü</li>
                        <li>3 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>42. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>100 koin</li>
                        <li>1 x harf açma gücü</li>
                        <li>1 x ipucu gücü</li>
                        <li>3 x pas gücü</li>
                        <li>3 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>43. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>100 koin</li>
                        <li>1 x harf açma gücü</li>
                        <li>1 x ipucu gücü</li>
                        <li>3 x pas gücü</li>
                        <li>3 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>44. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>100 koin</li>
                        <li>1 x harf açma gücü</li>
                        <li>1 x ipucu gücü</li>
                        <li>3 x pas gücü</li>
                        <li>3 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>45. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>100 koin</li>
                        <li>1 x harf açma gücü</li>
                        <li>1 x ipucu gücü</li>
                        <li>3 x pas gücü</li>
                        <li>3 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>46. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>100 koin</li>
                        <li>1 x harf açma gücü</li>
                        <li>1 x ipucu gücü</li>
                        <li>3 x pas gücü</li>
                        <li>3 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>47. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>100 koin</li>
                        <li>1 x harf açma gücü</li>
                        <li>1 x ipucu gücü</li>
                        <li>3 x pas gücü</li>
                        <li>3 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>48. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>100 koin</li>
                        <li>1 x harf açma gücü</li>
                        <li>1 x ipucu gücü</li>
                        <li>3 x pas gücü</li>
                        <li>3 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>49. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>100 koin</li>
                        <li>1 x harf açma gücü</li>
                        <li>1 x ipucu gücü</li>
                        <li>3 x pas gücü</li>
                        <li>3 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>50. Sıra</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>100 koin</li>
                        <li>1 x harf açma gücü</li>
                        <li>1 x ipucu gücü</li>
                        <li>3 x pas gücü</li>
                        <li>3 x geri sarma gücü</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div>
                      <p>51 - ∞</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <ul>
                        <li>50 koin</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!--h2 id="hediye-ceki" class="mb-4">Hediye Çekleri Kullanım Kılavuzu</h2>
          <ul class="list-disc ml-6">
            <li>
              Alışveriş sonrasında kalan bakiye aynı kod ile tekrar kullanılabilir.
            </li>
            <li>Hediye Kodu altın ve cep telefonu ve dijital ürün kategorilerinde geçerli değildir.</li>
            <li>Hızlı Market & Yemek dahildir.</li>
            <li>Hediye kod tutarı üstüne cüzdana istenilen miktarda para aktarılabilir.</li>
            <li>Hediye kodu iptal ve iade durumunda sipariş tutarı tekrar cüzdana yüklenir.</li>
            <li>Hediye kodlarının kullanım süresi bir yıldır.</li>
            <li>Mevcut Trendyol hesabınız ile giriş yapabilirsiniz. Eğer Trendyol hesabınız yoksa hesap oluşturmanız gerekmektedir.</li>
            <li>Trendyol hesabınıza giriş yaptıktan sonra cüzdan sekmesine tıklayıp, cep telefonu numaranız ile cüzdanınızı aktif etmeniz gerekmektedir.</li>
          </ul-->
        </div>
      </div>
      <network-status-modal ref="networkStatusModal"></network-status-modal>
    </div>
  </div>
</template>
<script>

import NetworkStatusModal from "@/views/components/NetworkStatusModal";
import TheHeader from "@/guide/components/TheHeader";
import {mapActions} from "vuex";
import UID from "uniquebrowserid";
import Feed from "@/mixins/Feed"

export default {
  components: {NetworkStatusModal, TheHeader},
  mixins: [Feed],
  data(){
    return {
      networkStatus : navigator.onLine
    }
  },
  created() {
    let queries = this.$route.query;
    if(queries.gclid){
      this.$cookies.set('origin-campaign-id', queries.gclid);
    }
    if(queries.fbclid) {
      this.$cookies.set('origin-campaign-id', queries.fbclid);
    }
    if(queries.ref_id){
      this.$cookies.set('fb-share-return-id', queries.ref_id);
    }

    if(this.$store.state.authenticated)
    {
      this.$gtag.config({userId: localStorage.getItem('playerId'), user_id: localStorage.getItem('playerId')});
      this.$cookies.set('aa_userId', localStorage.getItem('playerId'));
    }else{
      if(!localStorage.getItem('aa_deviceId')){
        let deviceIdTmp = new UID().completeID();
        localStorage.setItem('aa_deviceId',deviceIdTmp);
      }
      
      var deviceId = localStorage.getItem('aa_deviceId');

      let cookie = this.$cookies.get("origin-campaign-id")
      let payload = {deviceId: deviceId}
      if(cookie){ payload.originCampaignId = cookie }
      this.guestLogin(payload)
    }

    window.ononline = (event) => {
      console.log(event,"You are now connected to the network.");
    };

    window.addEventListener('online', this.updateConnectionStatus);
    window.addEventListener('offline', this.updateConnectionStatus);

    if (typeof (window.FBInstant) != 'undefined') {
      window.FBInstant.setLoadingProgress(100);
      window.FBInstant.startGameAsync()
          .then(function () {
          })
    }
  },
  methods: {
    ...mapActions(["guestLogin", "getPlayerNotifications"]),
    updateConnectionStatus() {
     this.networkStatus = navigator.onLine
    }
  },
  watch:{
    networkStatus: function (val) {
      if(val === false) {
        this.$refs.networkStatusModal.show();
      }else if(val === true){
        this.$refs.networkStatusModal.hide()
      }
    }
  }
}
</script>
