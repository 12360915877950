<template>
  <div id="app">
    <div class="layout-main vld-parent">
      <the-header></the-header>
      <div class="main mx-auto">
        <div class="flex container px-8 mt-8 flex-col items-center justify-center">
          <h1 class="mt-2 mb-2">REHBER</h1>
          <h2 class="mb-6">Oyun Kuralları</h2>
          <ul class="list-disc ml-5 mb-4">
            <li>
              Hedefin en az harfe basarak gizlenmiş kelimeyi doğru bir biçimde bulmaktır.
            </li>
            <li>
              Oyun ekranında skorun, harf açma hakkın, toplam bilet sayın, toplam koin sayın, sahip olduğun güçlerin sayısı
              gösterilir.
            </li>
            <li>
              Kelimeyi açmak için 7 hakkın bulunmakta. İlk harf açışında bir hak düşer. Bundan sonra her hatalı harf
              seçiminde 1 hak düşer.
            </li>
            <li>
              Verilen maksimum tahmin hakkında doğru tahminde bulunamazsan oyun sonlanır ve o ana kadar kazandığın skoru toplarsın.
            </li>
            <li>
              Sana verilen tahmin hakkı içerisinde kelimeyi doğru olarak bilirsen başarı seviyene göre bonus ödüller
              alırsın.
            </li>
            <li>
              Her lig oyunu açmak 1 bilet değerindedir.
            </li>
            <li>
              Tüm oyuncuları ligdeki mevcut puan ve sıralamalarını skor tablosu sayfasından takip edebilirsin.
            </li>
            <li>Her oyuncu oyuna 5 biletle başlar.</li>
            <li>Her tükenen bilet 12 dakikada bir ücretsiz yüklenir.</li>
            <li>Biletlerin tükendikten sonra ödüllü ligde daha fazla oynamaya ve kazanmaya devam etmek için biletlerinin yenilenmesini beklemen, mağazadan bilet alman veya ödüllü reklam izlemen gerekir.</li>
            <li>Oyunun sonunda kazandığın skorlar seni sıralamada üstlere taşır.</li>
            <li>Sıralamada ne kadar üst sırada olursan o kadar çok ödül kazanırsın.</li>
            <li>Oyun sırasında sahip olduğun güçlerden herhangi birini istediğin kadar sayıda kullanabilir ve bu sayede daha yüksek puan alabilirsin.</li>
            <li>Ayrıca oyun sonunda 2 kat daha fazla skor kazanmak için ödüllü reklamları izleyebilirsin. 2 kat fazla skor kazanma her doğru bilinen kelime için tek seferliktir.</li>
          </ul>
          <h2 class="mt-6 mb-4">Güçler</h2>
          <li>Geri Sarma gücü hatalı bir hamleyi geri sarar ve sanki o hamle hiç yaşanmamış gibi harcanan canı da geri yükler.</li>
          <li>Harf Açma gücü aranılan kelimede henüz açılmamış harflerden rastgele bir tanesini açmak için kullanılır.</li>
          <li>Pas gücü oyuncunun bilet harcamadan rastgele yeni bir oyun açmasına olanak sağlar.</li>
          <li>İpucu gücü açılacak kelime ile ilgili ipuçları verir. Birden fazla anlam içeren kelimelerde ipuçları farkı anlamlardan çıkar.Bu güç sadece ilgili soruya ait ipucu sayısı kadar kullanılabilir.</li>
          <h2 class="mt-6 mb-4">Ligler</h2>
          <p>
            Hafta içinde oynadığın oyunlar dan kazandığın puanlar lig sıralamalarını belirler. Hafta sonunda, bulunduğun
            sıralamaya göre ücretsiz ödüller kazanabilirsin.
          </p>
          <ul class="list-disc ml-5 mb-4">
            <li>
              Ligler her pazartesi saat 00:30 ve pazar saat 23:30'a kadar açık kalır.
            </li>
            <li>
              Pazar günü saat 23:30'dan sonra kapanır.
            </li>
            <li>
              Ligler kapandıktan sonraki gün saat 09:15'da kullanıcılara ödülleri yüklenir.
            </li>
            <li>
              Liglerin kapalı olduğu aralıkta puanlar kaydedilir, ligler tekrar açıldığında liderlik panosunda görülür.
            </li>
          </ul>
          <h2 class="mb-6">GB Paketleri Kullanım Kılavuzu</h2>
          <ul class="list-disc ml-5 mb-4">
            <li>
              İnternet paketlerinin son kullanım tarihleri paketler hattınıza tanımlandıktan sonra sms mesajı ile bildirilecektir.
            </li>
            <li>
              İnternet paketleri yalnızca Türkiye'deki kullanımlarda geçerlidir.
            </li>
            <li>
              İnternet paketleri yalnızca Türk Telekom, Turkcell ve Vodafone numaraları için geçerlidir.
            </li>
            <li>
              İnternet paketleri iade edilemez ve devredilemez.
            </li>
          </ul>
        </div>
      </div>
      <network-status-modal ref="networkStatusModal"></network-status-modal>
    </div>
  </div>
</template>
<script>

import NetworkStatusModal from "@/views/components/NetworkStatusModal";
import TheHeader from "@/guide/components/TheHeader";
import {mapActions} from "vuex";
import UID from "uniquebrowserid";
import Feed from "@/mixins/Feed"

export default {
  components: {NetworkStatusModal, TheHeader},
  mixins: [Feed],
  data(){
    return {
      networkStatus : navigator.onLine
    }
  },
  created() {
    let queries = this.$route.query;
    if(queries.gclid){
      this.$cookies.set('origin-campaign-id', queries.gclid);
    }
    if(queries.fbclid){
      this.$cookies.set('origin-campaign-id', queries.fbclid);
    }
    if(queries.ref_id){
      this.$cookies.set('fb-share-return-id', queries.ref_id);
    }

    if(this.$store.state.authenticated)
    {
      this.$gtag.config({userId: localStorage.getItem('playerId'), user_id: localStorage.getItem('playerId')});
      this.$cookies.set('aa_userId', localStorage.getItem('playerId'));
    }else{
      if(!localStorage.getItem('aa_deviceId')){
        let deviceIdTmp = new UID().completeID();
        localStorage.setItem('aa_deviceId',deviceIdTmp);
      }
      
      var deviceId = localStorage.getItem('aa_deviceId');

      let cookie = this.$cookies.get("origin-campaign-id")
      let payload = {deviceId: deviceId}
      if(cookie){ payload.originCampaignId = cookie }
      this.guestLogin(payload)
    }

    window.ononline = (event) => {
      console.log(event,"You are now connected to the network.");
    };

    window.addEventListener('online', this.updateConnectionStatus);
    window.addEventListener('offline', this.updateConnectionStatus);

    if (typeof (window.FBInstant) != 'undefined') {
      window.FBInstant.setLoadingProgress(100);
      window.FBInstant.startGameAsync()
          .then(function () {
          })
    }
  },
  methods: {
    ...mapActions(["guestLogin", "getPlayerNotifications"]),
    updateConnectionStatus() {
     this.networkStatus = navigator.onLine
    }
  },
  watch:{
    networkStatus: function (val) {
      if(val === false) {
        this.$refs.networkStatusModal.show();
      }else if(val === true){
        this.$refs.networkStatusModal.hide()
      }
    }
  }
}
</script>
